import React, { useState } from "react"
import Head from "../../components/global/head"

import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import ContentBoundary from "../../components/content/boundary"
import HeroIntroBanner from "../../components/modules/hero_intro_banner"

const TermsAndConditions = ({ data }) => {
  const { termsConditionsNode } = data.allDatoCmsHome.edges[0].node

  return (
    <>
      <Head
        title="Terms and Conditions"
        url="https://www.choosetap.com.au/terms-and-conditions"
        description="National Choose Tap coalition website terms and conditions."
        keywords="choose tap legal, choose tap terms and conditions
        choose tap legal, choose tap terms and conditions"
        bodyClassname="body__terms-and-conditions"
      />

      <section className="pages__404">
        <HeroIntroBanner
          title="Terms & Conditions"
          sectionName="Legals"
          themeColor="grey"
        />
        <main>
          <ContentBoundary>
            <div
              style={{ maxWidth: "577px", margin: "0 auto" }}
              className="util__margin-bottom--100"
            >
              <ReactMarkdown
                source={termsConditionsNode.childMarkdownRemark.rawMarkdownBody}
              />
            </div>
          </ContentBoundary>
        </main>
      </section>
    </>
  )
}

export const query = graphql`
  query {
    allDatoCmsHome {
      edges {
        node {
          termsConditionsNode {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }
  }
`

export default TermsAndConditions
